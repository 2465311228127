<template>
  <b-container>
    <div class="cui__utils__heading">
      <strong>Edit User</strong>
    </div>
    <b-card>
      <template v-if="user && customerGroup">
        <b-card class="mb-5" bg-variant="light" border-variant="secondary" text-variant="primary"
          v-if="temporaryPassword.length">
          <div class="d-flex w-100 align-items-center">
            <label class="h5 mr-2 text-nowrap mb-0" for="temporary-password">Temporary Password:</label>
            <div>
              <b-form-input class="d-inline w-auto text-monospace" readonly v-model="temporaryPassword"
                id="temporary-password" ref="temporaryPassword"
                @click="$refs.temporaryPassword.select()"></b-form-input>
            </div>
          </div>
        </b-card>
        <EditUserProfileForm @onCreateTempPasswordClick="onCreateTempPasswordClick" :customerGroup="customerGroup"
          :errors="errors" :user="user" @submit="onProfileSubmit" />
      </template>
      <b-spinner v-else></b-spinner>
    </b-card>
  </b-container>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import EditUserProfileForm from '@/components/users/editUserProfileForm.vue';
import generateNonce from '@/util/generateNonce';

export default {
  components: {
    EditUserProfileForm
  },
  data() {
    return {
      user: null,
      updatedUser: null,
      errors: [],
      roleAssignments: [],
      userId: '',
      userCustomerGroup: null,
      temporaryPassword: '',
      roleChange: false,
    }
  },
  computed: {
    ...mapState('ping', {
      actorRoleAssignments: 'roleAssignments',
    }),
    ...mapState('ocapi', {
      actorCustomerGroup: 'customerGroup'
    }),
    ...mapState('ping', [
      'environmentId',
      'roles',
      'newUserId',
      'newUserTemporaryPassword'
    ]),
    ...mapGetters('ping', [
      'isAdmin',
      'isAccountAdmin',
      'IDARoleID',
    ]),
    populationOptions() {
      return this.actorRoleAssignments
        .filter(role => role.scope.type === 'POPULATION')
    },
    customerGroup() {
      if (this.isAdmin || this.isAccountAdmin) {
        return this.userCustomerGroup;
      }
      return this.actorCustomerGroup;
    }
  },
  methods: {
    ...mapActions('ping', [
      'fetchUser',
      'updateUser',
      'fetchRoles',
      'fetchUserRoleAssignments',
      'createUserIDARole',
      'deleteUserRoleAssignment',
      'passwordReset',
    ]),
    ...mapActions('ocapi', [
      'fetchCustomerGroup',
    ]),
    ...mapMutations('ping', [
      'setNewUserId',
      'setNewUserTemporaryPassword',
    ]),
    ...mapMutations('admin', [
      'startLoading',
      'stopLoading',
    ]),
    onCreateTempPasswordClick(e) {
      const temporaryPassword = generateNonce(8);
      this.passwordReset({
        userId: this.userId,
        password: temporaryPassword
      }).then(() => {
        this.temporaryPassword = temporaryPassword;
      })
    },
    onProfileSubmit(data) {
      // if role change
      this.roleChange = this.user?.profile?.role !== data.profile.role;

      this.startLoading();
      this.updateUser({
        userId: this.user.id,
        data
      })
        .then(response => {
          this.updatedUser = response.data;
        })
        .then(() => {
          if (this.roleChange) {
            return Promise.all([
              // delete existing roles
              ...this.roleAssignments.map(roleAssignment => this.deleteUserRoleAssignment({
                userId: this.userId,
                roleAssignmentId: roleAssignment.id
              })),
            ])
              .then(() => {
                if (data.profile.role === 'admin' || data.profile.role === 'accountadmin') {
                  // create IDA environment role
                  return this.createUserIDARole({
                    userId: this.userId,
                    type: 'environment',
                    id: this.environmentId
                  })
                } else if (data.profile.role === 'clientadmin' || data.profile.role === 'reporting') {
                  // create IDA population role
                  return this.createUserIDARole({
                    userId: this.userId,
                    type: 'population',
                    id: this.customerGroup.id
                  })
                }
              });
          }
          return Promise.resolve();
        })
        .then(() => {
          this.user = this.updatedUser;
          this.updatedUser = null;
          return this.refreshUserRoleAssignments();
        })
        .then(() => {
          this.stopLoading();
        })
        .catch(error => {
          console.error(error);
          this.stopLoading();
        })
    },
    refreshUserRoleAssignments() {
      return new Promise((resolve, reject) => {
        this.fetchUserRoleAssignments(this.userId)
          .then(response => {
            this.roleAssignments = response.data._embedded.roleAssignments;
            resolve();
          })
          .catch(reject)
      })
    },
    onIDARoleSubmit(data) {
      console.log(data);
      Promise.all([
        // make create call for each population id
        ...data.create.map(id => this.createUserIDARole({
          userId: this.userId,
          populationId: id
        })),
        // make delete call for each role
        ...data.delete.map(roleAssignmentId => this.deleteUserRoleAssignment({
          userId: this.userId,
          roleAssignmentId
        }))
      ])
        .then(console.log)
        .catch(console.log)
    },
  },
  mounted() {
    this.userId = this.$route.params.id;
    if (this.userId === this.newUserId && this.newUserTemporaryPassword.length) {
      this.temporaryPassword = this.newUserTemporaryPassword;
      this.setNewUserId('');
      this.setNewUserTemporaryPassword('');
    }
    Promise.all([
      this.fetchRoles(),
      this.refreshUserRoleAssignments(),
      this.fetchUser(this.userId)
        .then(response => {
          this.user = response.data;
          return this.fetchCustomerGroup(this.user.population.id)
        })
        .then(response => {
          this.userCustomerGroup = response.data;
        })
    ])
      .catch(console.log)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../src/components/kit/core/mixins.scss';

.split-list-search {
  background-color: $dark-gray-4;
}

.flex-1 {
  flex: 1;
}

.split-list {
  height: 300px;
}
</style>
